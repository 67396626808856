import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Emilus';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const API_BACK_URL = env.API_ENDPOINT_BACK_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const RAPID_KEY="6d4d36183fmsh4a3de63e2a6336ep1c40d4jsn4a818ad2395a";
export const TMDB_KEY="fe8bb4ee7e7dde04aaf94e541759bbfd";


export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	RAPID_KEY,
	TMDB_KEY
};
